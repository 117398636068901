import { useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react";
import { getData, setData } from '../../store/Store'

function RaceControl(props, ref) {

  const [startTime, setStartTime] = useState();
  const [timer, setTimer] = useState();
  const [seconds, setSeconds] = useState();
  const [intervalId, setIntervalId] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState();
  const [participants, setParticipants] = useState([]);
  const [stamps, setStamps] = useState();
  const [race, setRace] = useState({});
  const [result, setResult] = useState();

  useImperativeHandle(ref, () => ({
    generateResults: () => { return generateResults() },
    startRace: () => { return startRace() },
    stopRace: () => { return stopRace() },
    clearStamps: () => { return clearStamps() },
    getStamps: () => { return stamps },
    refresh: refresh,
    isRunning: isRunning
  }))

  useEffect(() => {
   refresh();

  }, [])

  const refresh = () => {
    getData('participants').then(result => {
      setParticipants(result || []);
    })
    getData('stamps').then(result => {
      setStamps(result || []);
    })
    getData('timer').then(result => {
      setStartTime(result);
      if (result && !intervalId)
        startRace(result)
    })
    getData('race').then(result => {
      setRace(result);
    })
    getData('result').then(result => {
      setResult(result);
    })
  }

  useEffect(() => {
    setIsRunning(intervalId ? true : false);
  }, [intervalId])

  const selectParticipant = (startnummer) => {
    if (startnummer) {
      setSelectedParticipant(participants.find(p => p.startnummer == startnummer) || false);
    }
    else {
      setSelectedParticipant(false)
    }
  }

  const toTime = (seconds) => {
    var date = new Date(null);
    date.setMilliseconds(seconds);
    return date.toISOString().substr(11, 10);
  }

  const startRace = (continueFrom) => {

    if (intervalId)
      return intervalId;

    var start = continueFrom || startTime || Date.now();
    setStartTime(start);
    const i = setInterval(function () {
      var delta = Date.now() - (start); // milliseconds elapsed since start
      setTimer(toTime(delta))
      setSeconds(delta);
    }, 100)
    setIntervalId(i)
    setData('timer', start);
  }

  const stopRace = () => {
    if (window.confirm('Är du säker?')) {
      clearInterval(intervalId);
      setIntervalId(false);
      setTimer();
      setData('timer', false);
      generateResults();
    }
  }

  const clearStamps = () => {
    if (window.confirm('Är du säker?')) {
      return setData('stamps', []).then(result => {
        setStamps(result);
        return result
      })
    }
  }

  const generateResults = () => {

    const sortByTime = (a, b) => {
      if (a.time < b.time)
        return -1;
      if (a.time > b.time)
        return 1;
      return 0;
    }

    const sortByStarted = (a,b) => {
      if(a.startnummer && !b.startnummer)
        return -1;
      if(!a.startnummer && b.startnummer)
        return 1;
      if(a.lastName.toLowerCase() < b.lastName.toLowerCase())
        return -1;
      if(a.lastName.toLowerCase() > b.lastName.toLowerCase())
        return 1;
      return 0;
    }

    let resultList = []

    race?.klasser?.map(klass => {
      /* Sort all finishers  */
      let i = 1;
      stamps.filter(stamp => stamp.participant.klass.uuid == klass.uuid && stamp.finalLap).sort(sortByTime).map((stamp, key) => {
        resultList.push({ participant: stamp.participant, placering: i++, stamp })
      })

      participants.filter(participant => participant.klass.uuid == klass.uuid).sort(sortByStarted).map(participant => {
        
        if(!resultList.find(placering => placering.participant.uuid == participant.uuid))
        {
          resultList.push({participant,placering: i++,dnf:true})
        }
      })
    })

    

    return setData('result', resultList).then(result => {
      setResult(result)
      return result;
    })

    return resultList;
  }

  useEffect(() => {
    if (props.onAddLap && stamps) {
      props.onAddLap(stamps)
    }
  },[stamps])

  const addLap = (event) => {
    event.preventDefault();

    if (!intervalId)
      return false;
    
    if (selectedParticipant) {
      const currentStamps = stamps.filter(stamp => stamp.participant.startnummer == selectedParticipant.startnummer);
      if (currentStamps.length < selectedParticipant.klass.laps) {
        const newStamps = [...stamps, { participant: selectedParticipant, seconds: seconds, time: timer, finalLap: (currentStamps.length + 1 == selectedParticipant.klass.laps) }];
        setData('stamps', newStamps).then((result) => {
          setStamps(result);
        })
      }
    }

    event.target.reset()
    selectParticipant(false)
  }



  return (
    <div className="raceControl">
      <div className="raceTimer">
        {/*<input type="button" onClick={generateResults}/>*/}
        {!isRunning &&
          <input type="button" value="Starta lopp" onClick={() => { startRace(startTime) }} />
        }
        <span className="raceTime">{timer}</span>
      </div>
      {isRunning &&
        <>
          <div className="addLapForm">
            <form onSubmit={(event) => { addLap(event) }}>
              <input placeholder="Startnr" autoFocus size={7} type="numer" min={0} max={9999} onInput={(event) => { selectParticipant(event.target.value) }} />
              <input disabled size={40} value={selectedParticipant && `${selectedParticipant?.firstName} ${selectedParticipant?.lastName}, ${selectedParticipant?.klubb}` || ''} />
              <input type="submit" value="Mål/varvning" />
            </form>
          </div>
        </>
      }
      {isRunning && <input type="button" className="cancelButton" value="Stoppa lopp" onClick={stopRace} />}
    </div>
  )

}
RaceControl = forwardRef(RaceControl);
export default RaceControl