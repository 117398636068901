import { useEffect, useState, useRef } from "react";
import { getData, setData } from "../store/Store";
import RaceControl from "../components/Old/RaceControl";

function Index(props) {

  const [stamps, setStamps] = useState();
  const [race, setRace] = useState({});
  const raceControlRef = useRef();

  useEffect(() => {
    const callback = () => {
      getData('race').then(result => {
        setRace(result)
      })
      getData('stamps').then(result => {
        setStamps(result || []);
      })

    }
    callback();

  }, [])

  const refreshStamps = (stamps) => {
    setStamps(stamps)
  }

  const clearStamps = () => {

    if (raceControlRef.current?.isRunning) {
      alert("Du kan inte tömma loggen när loppet startat.")
      return false;
    }
    const result = raceControlRef.current?.clearStamps();
    result.then(stamps => {
      setStamps(stamps)
    })
  }

  return (
    <>
      <h1>{race?.name}</h1>
      <RaceControl onAddLap={refreshStamps} ref={raceControlRef} />

      <h3>Stämpellog</h3>
      <table className="list">
        <thead>
          <tr>
            <th className="time">Tid</th>
            <th>Deltagare</th>
            <th>Klass</th>
            <th>Mål/Varv</th>
          </tr>
        </thead>
        <tbody>
          {stamps?.map((stamp, key) => {
            return (
              <tr key={key} className="stamp">
                <td className="time">{stamp.time}</td>

                <td className="name">
                  {stamp.participant.firstName} {stamp.participant.lastName}<br />
                  <span className="klubb">{stamp.participant.klubb}</span>
                </td>
                <td className="klass">
                  {stamp.participant.klass.name} {stamp.participant.klass.distance}km
                </td>
                {stamp.finalLap && <td className="finalLap">Målgång</td>}
                {!stamp.finalLap && <td className="lap">Varvning</td>}
              </tr>
            )
          })}
        </tbody>
      </table>

      {stamps?.length > 0 &&
        <p>
          <input type="button" className="cancelButton" value="Rensa tidstämplar" onClick={() => { clearStamps() }} />
        </p>
      }
    </>
  )

}

export default Index;