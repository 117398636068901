import { useApi } from "../hooks/ApiProvider";

export default function Api() {
  const Rest = useApi();
  const Api = {
    /* Races */
    getRaces: () => {
      return Rest.get('/races');
    },

    /* Race */ 
    getRace: (raceId) => {
      console.log('getRace', raceId);
      return Rest.get(`/races/${raceId}`);
    },
    getParticipants: (raceId) => {
      return Rest.get(`/races/${raceId}/participants`);
    },
    getCourses: (raceId) => {
      return Rest.get(`/races/${raceId}/courses`);
    },
    getClasses: (raceId) => {
      return Rest.get(`/races/${raceId}/classes`);
    },
    getRaceSplits: (raceId) => {
      return Rest.get(`/races/${raceId}/splits`)
    },

    /* course */
    getCourse: (raceId, courseId) => {
      return Rest.get(`/races/${raceId}/courses/${courseId}`);
    },
    getCourseSplits: (raceId, courseId) => {
      return Rest.get(`/races/${raceId}/courses/${courseId}/splits`);
    },
    saveCourse: (raceId, courseId,course) => {
      return Rest.put(`/races/${raceId}/courses/${courseId}`, { course });
    },
    addSplitToCourse: (raceId, courseId, splitId) => {
      return Rest.post(`/races/${raceId}/courses/${courseId}/splits`, { splitId });
    },
    deleteSplitFromCourse: (raceId, courseId, courseSplitId) => {
      return Rest.delete(`/races/${raceId}/courses/${courseId}/splits/${courseSplitId}`);
    },
    saveCourseSplit: (raceId, courseId, courseSplit) => {
      return Rest.put(`/races/${raceId}/courses/${courseId}/splits/${courseSplit.courseSplitId}`, { courseSplit });
    },
    createCourse: (raceId, course) => {
      return Rest.post(`/races/${raceId}/courses`, { course });
    },

    /* Participant */
    getParticipant: (participantId, raceId) => {
      if (raceId)
        return Rest.get(`/races/${raceId}/participants/${participantId}`);
      return Rest.get(`/participants/${participantId}`);
    },
    getHistory: (participantId) => {
      return Rest.get(`/participants/${participantId}/history`);
    },
    getParticipantSplits: (raceId, participantId) => {
      return Rest.get(`/races/${raceId}/participants/${participantId}/splits`);
    },
    searchBib: (raceId, bib) => {
      return Rest.get(`/races/${raceId}/bib/${bib}`);
    },
    saveParticipant(raceId, participant) {
      if(participant.ID)
        return Rest.put(`/races/${raceId}/participants/${participant.ID}`, { participant });
      else
        return Rest.post(`/races/${raceId}/participants`, { participant });
    },
    deleteParticipantFromRace: (raceId, participantId) => {
      return Rest.delete(`/races/${raceId}/participants/${participantId}`);
    },
    deleteParticipant: (participantId) => {
      return Rest.delete(`/participtants/${participantId}`);
    },
    /* class */ 
    saveClass: (raceClass) => {
      return Rest.put(`/races/${raceClass.raceId}/classes/${raceClass.ID}`, { class: raceClass });
    },
    createClass: (raceId, raceClass) => {
      return Rest.post(`/races/${raceId}/classes`, { class: raceClass });
    },
    
    /* Split */
    addStamp: (raceId, splitId, stamp) => {
      return Rest.post(`/races/${raceId}/splits/${splitId}/stamps`, { stamp: stamp });
    },
    getStamps: (raceId, splitId) => {
      return Rest.get(`/races/${raceId}/splits/${splitId}/stamps`);
    },
    
    /* stamp */
    updateStamp: (stamp) => {
      return Rest.put(`/stamps/${stamp.ID}`, { stamp: stamp });
    },
    deleteStamp: (stampId) => {
      return Rest.delete(`/stamps/${stampId}`);
    },
    addSplitToCourse: (raceId, courseId, splitId) => {
      return Rest.post(`/races/${raceId}/courses/${courseId}/splits`, { splitId: splitId });
    },
    deleteSplitFromCourse: (raceId, courseId, courseSplitId) => {
      return Rest.delete(`/races/${raceId}/courses/${courseId}/splits/${courseSplitId}`);
    },
    
  }
  return Api;
}