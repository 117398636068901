import { useEffect, useState } from "react";


const Tabs = (props) => {

  const [tabs, setTabs] = useState([])
  const [inFocusIndex, setInFocusIndex] = useState(props.inFocusIndex || 0);
  

  useEffect(() => {
    const t = Array.isArray(props.children) ? props.children : [props.children];
    setTabs(t.filter(t => t))
  }, [props.children])

  const changePage = (index) => {
    setInFocusIndex(index);
  }

  return (
    tabs.length == 1 ?
      props.children
      :
      <>
        <div className="tabs">
          <div className="tabBar">
            {tabs.map((tab, index) => {
              return (
                <a onClick={() => { changePage(index) }} key={index} className={inFocusIndex==index ? 'tab active' : 'tab'}>
                  <span className="tabText">{tab.props.title || 'Tab'}</span>
                </a>
              )
            })}
          </div>
        </div>
        {tabs.map((tab, index) => {
          return (
            <Tab key={index} index={index} inFocusIndex={inFocusIndex}>
              {tab}
            </Tab>
          )
        })}

      </>
  )

}

const Tab = (props) => {
  const { children, index } = props;
  const [isInFocus, setIsInFocus] = useState(index == props.inFocusIndex);

  useEffect(() => {
    setIsInFocus(index == props.inFocusIndex);
  }, [props.inFocusIndex])

  return (
    isInFocus ? children : null
  )
}

export { Tabs, Tab };