import moment from "moment";
import { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import Api from "../../api/Api";
import { Context } from "../../redux/Context";
import MaterialCommunityIcons from "../MaterialCommunityIcons";

export default function Race(props) {

  const [race, setRace] = useState(props.race);
  const { raceId } = useParams();
  const dataStore = Api();

  useEffect(() => {
    if(raceId) 
      refreshRace();
  }, [raceId]);


  const refreshRace = () => {
    getRace();
    getClasses();
    getCourses();
  }


  const getClasses = () => {
    dataStore.getClasses(raceId).then(data => {
      setRace((race) => ({...race, classes: data}))
    }).catch(error => {
      console.log(error);
    })
  }

  const getCourses = () => {
    dataStore.getCourses(raceId).then(data => {
      setRace((race) => ({...race, courses: data}))
    }).catch(error => {
      console.log(error);
    })
  }
  const getRace = () => {
    dataStore.getRace(raceId).then(data => {
      console.log('getRace',data);
      setRace((prev) => ({...prev,...data}));
    }).catch(error => {
      console.log(error);
    })
  }
  
  return (
    <Context.Provider prop value={{race,refreshRace}}>
      {race ?
        <div className="race">
          <div className="raceStatus iconBox">
            {race.status == 'started' && <div className="raceStartedBlob"></div>}
            {race.status == 'not started' && <MaterialCommunityIcons name="mdiTimerOutline" />}
            {race.status == 'ended' && <MaterialCommunityIcons name="mdiFlagCheckered" />}
          </div>

          <div className="raceInfo">
            <Link to={`/races/${race.ID}`} className="raceName">{race.name}</Link>
            <div className="flex">
              <div className="raceDate iconBox">
                <MaterialCommunityIcons name="mdiCalendarMonthOutline" />
                &nbsp;
                {moment.unix(race.startDate).format('L')}
              </div>
              <div className="raceLocation">{race.location}</div>
            </div>
          </div>
          <div className="raceMenu">
            <NavLink to={`/races/${race.ID}/result`}  className={({ isActive }) => isActive ? 'active' : undefined} >Resultat</NavLink>
            <NavLink to={`/races/${race.ID}/participants`} className={({ isActive }) => isActive ? 'active' : undefined} >Deltagare</NavLink>
            <NavLink to={`/races/${race.ID}/courses`}  className={({ isActive }) => isActive ? 'active' : undefined} >Banor</NavLink>
            <NavLink to={`/races/${race.ID}/classes`}  className={({ isActive }) => isActive ? 'active' : undefined} >Klasser</NavLink>
            <NavLink to={`/races/${race.ID}/splits`} className={({ isActive }) => isActive ? 'active' : undefined} >Tidtagning</NavLink>
          </div>
        </div> : null}
      <Outlet />
      </Context.Provider>
  )
}

