import { storeData, getData } from '../redux/store';
import { getUnsecureData, storeUnsecureData } from '../redux/unsecureStore';

import jwt_decode from "jwt-decode";
import { useContext } from 'react';
import { Context } from '../redux/Context';


export function useApi() {

  const appContext = useContext(Context);
  const baseUrl = 'https://timer.stigloparn.se:17654'; 

  const Api = {
    registerDevicePushTokenAsync: async (token) => {
      await Api.post('/registerDevicePushToken', { token: token }).then(result => {

      }
      ).catch(e => {
        console.log(e)
      })
    },
    unRegisterDevicePushTokenAsync: async (token) => {
      Api.delete('/registerDevicePushToken', { token: token }).then(result => { }).catch(e => { })
    },
    // Saves token to local storage
    setToken: async (token) => {
      return storeData('token', token);
    },
    // Get stored token from local storage
    getToken: async () => {
      const token = await getData('token');
      return token;
    },
    // Stores list of tags
    setTags: async (tags) => {
      return storeUnsecureData('tags', tags);
    },

    
    // Try to refresh authToken using refreshToken in storage.
    refreshToken: async (token) => {
      // Throw an error and send logout if theres no token in storage
      if (!token || !token.refreshToken || !token.user) {
        appContext.dispatch({ type: 'logout' });
        throw new Error('No token in storage!');
        
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.authToken}`
        },
        body: JSON.stringify({ user: token.user, refreshToken: token.refreshToken })
      };
      return fetch(baseUrl + '/session/refreshToken', requestOptions).then(Api.handleResponse).then((token) => {
        return Api.setToken(token).then((token) => {
          return token;
        });
      }).catch(err => {
        // Send logout if refreshToken failed which means session has expired
        console.log('refreshToken failed, logging out', err)
        appContext.dispatch({ type: 'logout' });
        //return err
      })

    },

    // Checks if token has expired
    isTokenExpired: (token) => {
      let decodedToken = jwt_decode(token);
      let isExpired = true;
      if (decodedToken && decodedToken.exp) {
        const expirationDate = new Date(0);
        expirationDate.setUTCSeconds(decodedToken.exp);
        isExpired = expirationDate.valueOf() < new Date().valueOf();
      }
      return isExpired;
    },
    // Validates token in storage and try to refresh if expired or not present.
    validateToken: async () => {
      return Api.getToken().then((token) => {
        if (!token || !token.authToken || Api.isTokenExpired(token.authToken))
          return Api.refreshToken(token);
        return token;
      })
    },
    // Login user and get a sessionId 
    login: (credentials) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      };
      return fetch(baseUrl + '/session/login', requestOptions).then(Api.handleResponse).then((token) => {
        return Api.setToken(token).then((token) => {
          return token;
        });
      })
    },
    // Normal GET request
    get: (url, options) => {
      //console.log('get', url);
      return Api.validateToken().then(token => {
        const requestOptions = {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `Bearer ${token.authToken}`
          }
          ,
          ...options
        };
        return fetch(baseUrl + url, requestOptions).then(Api.handleResponse);
      })
    },
    // Normal POST request
    post: (url, body, options) => {
      //console.log('post', url, body);
      return Api.validateToken().then(token => {
        const requestOptions = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `Bearer ${token.authToken}`
          },
          body: JSON.stringify(body),
          ...options
        };
        return fetch(baseUrl + url, requestOptions).then(Api.handleResponse);
      })
    },
    // Normal PUT request
    put: (url, body, options) => {
      //console.log('put', url, body);
      return Api.validateToken().then(token => {
        const requestOptions = {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `Bearer ${token.authToken}`
          },
          body: JSON.stringify(body),
          ...options
        };
        return fetch(baseUrl + url, requestOptions).then(Api.handleResponse);
      })
    },
    // Normal DELETE request
    delete: (url, body, options) => {
      //console.log('delete', url, body);
      return Api.validateToken().then(token => {
        const requestOptions = {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `Bearer ${token.authToken}`
          },
          body: JSON.stringify(body),
          ...options
        };
        return fetch(baseUrl + url, requestOptions).then(Api.handleResponse);
      })
    },
    // Filter results from requests and checks of status is OK (HTTP 200)
    handleResponse: (response) => {
      return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.errorMessage) || response.statusText;
          return Promise.reject({ status: response.status, error: error });
        }
        return data;
      });
    },
    getSession: async () => {
      return await Api.getToken().then((token) => {
        if (!token)
          return false;
        else
          return token.user;
      })
    },
    loadOrganisations: (clientId) => {
      if(!clientId)
        return Promise.reject('No ID specified');
      return Api.get(`/clients/${clientId}/organisations`);
    },
    // Fetch current logged in user from token in local storage
    getUser: async (userId, forceUpdate) => {

      /* If no ID given, use logged in users ID */
      if (!userId) {
        userId = await Api.getToken().then((token) => {
          if (!token)
            appContext.dispatch({ type: 'logout' });
          else
            return token.user.ID;
        })
      }

      /* get userlist from local storage */
      let users = await getUnsecureData('users').then(data => {
        return data || []
      });

      /* check if we already fetched requested user from API */
      const key = users.findIndex(user => user.ID == userId);

      /* if found (key >= 0) and update date (timestamp) is no older than 1 hour return user object */
      if (key >= 0 && new Date().getTime() / 1000 - users[key].timestamp < 3600 && !forceUpdate)
        return { user: users[key] };

      /* else fetch user from API */
      const user = await Api.get(`/members/${userId}`).then((result) => {
        return result;
      }).catch(e => {
        throw e;
      })

      /*set update date */
      user.timestamp = new Date().getTime() / 1000;
      /* insert/replace in users array */
      users.splice(key > -1 ? key : users.length, 1, user)
      /* store in local storage  */
      await storeUnsecureData('users', users)
      /* return object */
      return { user };
    },
    // check if the item of @type with @ID has been seen or not
    seen: async (type, ID) => {
      const seen = await getData('seen');
      return true;
    },
    addToSeen: async (type, ID) => {
      const seen = await getData('seen');
      return true;
    }
  }



  return Api;

}
