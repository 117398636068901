import AsyncStorage from '@react-native-async-storage/async-storage';

export let storeData = async (key,value) => {
    try {
      const jsonValue = JSON.stringify(value)
      //console.log(`saved ${key} to store with value: ${jsonValue}`);
      await AsyncStorage.setItem(key, jsonValue)
      return value
    } catch (e) {
      console.log('error saving to store');
    }
  }
  
export let getData = async (key) => {
    try {
      const jsonValue = await AsyncStorage.getItem(key)
      //console.log(`fetched ${key} from store with value: ${jsonValue}`);
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch(e) {
        console.log('error saving to store');
    }
  }


export default getData;