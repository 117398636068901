import { BrowserRouter, Route, Routes, Link, NavLink, Outlet } from 'react-router-dom';
import Index from '../screens/Index'
import Participant from '../components/Races/Participant';
import Result from '../components/Races/Result';
import Participants from '../components/Races/Participants';
import Race from './Races/Race';
import Races from '../components/Races/Races';
import Course from '../components/Races/Course';
import Courses from '../components/Races/Courses';
import Class from '../components/Races/Class';
import Classes from '../components/Races/Classes';
import Icon, { MaterialCommunityIcons, Feather } from 'react-web-vector-icons';
import { getData } from '../store/Store';
import { useEffect, useReducer, useState } from 'react';
import { storeData } from '../redux/store';
import { useApi } from '../hooks/ApiProvider';
import { Context } from '../redux/Context';
import Login from '../screens/Login';
import Split from '../components/Races/Split';
import Splits from '../components/Races/Splits';

function reducer(state, action) {
  let newState;
  switch (action.type) {
    case 'login':
      newState = { ...state, isLoggedIn: true }
      storeData('state', newState);
      return newState;
      break;
    case 'logout':
      newState = { ...state, isLoggedIn: false }
      storeData('state', state);
      storeData('token', false);
      return newState;
      break;
    case 'INIT':
      newState = { ...state, ...action.state }
      storeData('state', newState);
      return newState;
      break;
  }
}

function init(defaultValues) {
  return {
    ...defaultValues,
    isLoading: true,
  };
}

function App() {

  const Api = useApi()

  const [state, dispatch] = useReducer(reducer, {}, init);
  const [session, setSession] = useState(false);

  useEffect(() => {
    getData('state').then((state) => {
      dispatch({ type: 'INIT', state: { ...state, isLoading: false } })
    })
    Api.getSession().then(session => {
      setSession(session);
    })
  }, []);

  const appContext = {
    state,
    dispatch,
  }

  const logout = () => {
    appContext.dispatch({ type: 'logout' });
  }

  if (state.isLoading)
    return false;
  else
    if (!state.isLoggedIn)
      return (<Context.Provider value={appContext}><Login /></Context.Provider>)
    else
      return (<Context.Provider value={appContext}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Index />} />
              <Route path="/races/" element={<Races />} />
              <Route path="/races/:raceId" element={<Race />}>
                <Route path='/races/:raceId' element={<Result/>}/>
                <Route path="/races/:raceId/result/*" element={<Result />} />
                <Route path="/races/:raceId/participants" element={<Participants />} />
                <Route path="/races/:raceId/participants/:participantId" element={<Participant />} />
                <Route path="/races/:raceId/courses" element={<Courses />} />
                <Route path="/races/:raceId/courses/:courseId" element={<Course />} />
                <Route path="/races/:raceId/classes" element={<Classes />} />
                <Route path="/races/:raceId/classes/:classId" element={<Class />}/>
                <Route path="/races/:raceId/splits/*" element={<Splits />} />
                <Route path="/races/:raceId/splits/:splitId" element={<Split />} />
              </Route>
              <Route path="*" element={<NoMatch />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Context.Provider>
      )
}

function Layout(props) {
  return (
    <>
      <header>
        <nav>
          <ul>
            <li className="title"><img src="/reacttimer_white.svg" className="logo" />ReactTimer v1.0</li>
            <li><Link to="/">Start</Link></li>
            <li><Link to="/participants">Deltagare</Link></li>
            <li><Link to="/result">Resultat</Link></li>
            <li><Link to="/races">Lopp</Link></li>
          </ul>
        </nav>
      </header>
      <main>
        <Outlet />
      </main>
      <footer>
        <p className='spacearound'>
          <a href="https://hälsinglandsmultisport.se/">Hälsinglands Multisport</a> |
          <a href="mailto:mattias@sundberg.it">mattias@sundberg.it</a>
        </p>
      </footer>
    </>
  )
}

function NoMatch() {
  return (
    <div>
      <h3>Oj! Du har försökt nå en sida som inte finns.</h3>
      <p>
        <Link to="/">Hoppa tillbaka till startsidan</Link>
      </p>
    </div>
  );
}

export { App };
