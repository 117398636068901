import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../api/Api";
import { Context } from "../../redux/Context";
import MaterialCommunityIcons from "../MaterialCommunityIcons";

export default function Stamp(props) {

  const { raceId, splitId } = useParams();
  const [stamp, setStamp] = useState(props.stamp);
  const [updatedStamp, setUpdatedStamp] = useState(props.stamp);
  const [bibInputVisible, setBibInputVisible] = useState(false);
  const datastore = Api();
  const context = useContext(Context);
  const bibInput = useRef();

  useEffect(() => {
    setStamp(props.stamp);
  }, [props.stamp]);

  useEffect(() => {
    setUpdatedStamp(stamp);
  }, [stamp]);

  const updateStamp = (e) => {
    e.preventDefault();
    datastore.updateStamp(updatedStamp).then(data => {
      setStamp(data);
      setBibInputVisible(false);
    }).catch(error => {
      console.log(error);
    });
  }

  const deleteStamp = (e) => {
    e.preventDefault();
    datastore.deleteStamp(stamp.ID).then(data => {
      context.deleteStamp(stamp);
    });
  }

  const searchBib = (bib) => {
    datastore.searchBib(raceId, bib || 'null').then(data => {
        setUpdatedStamp((prev) => ({
          ...prev,
          bib: bib,
          participantId: data.ID,
          firstName: data.firstName,
          lastName: data.lastName,
          club: data.club,
          classId: data.classId,
          className: data.className,
          courseName: data.courseName
        }));
      
    }).catch(error => {
      console.log(error);
    });
  }

  return (
    <div className={stamp.new ? 'stamp new' : 'stamp'}>

      <div className="bib">
        {bibInputVisible ?

          <input type="number" ref={bibInput} onKeyDownCapture={(e) => {
            if (e.keyCode == 13 || e.key == 'Enter')
              updateStamp(e)
            if (e.keyCode == 27 || e.key == 'Escape')
              setBibInputVisible(false)
          }}
            onChange={(e) => { searchBib(e.target.value) }} value={updatedStamp.bib || ''}
            onSubmit={() => { console.log('enter') }} min="0" max="9999" className="small" placeholder="BIB" />
          :
          <a onClick={() => {
            setBibInputVisible(!bibInputVisible);
          }}>{updatedStamp.bib || '---'}</a>
        }

      </div>

      <div className="time">
        {moment.unix(updatedStamp.timestamp).format("HH:mm:ss")}
      </div>

      <div className="participantInfo">
        <span className="name">{updatedStamp.firstName || '-'} {updatedStamp.lastName || '-'}</span>
        <span className="club">, {updatedStamp.club || '-'}</span>
      </div>

      <div className="flex">
        <div className="className iconBox">
          <MaterialCommunityIcons name="mdiTournament" />
          {updatedStamp.className || '-'}
        </div>
        <div className="courseName iconBox hideOnMobile">
          <MaterialCommunityIcons name="mdiGesture" />
          {updatedStamp.courseName || '-'}
        </div>
      </div>

      <div className="stampButtons">
        <button onClick={(e) => { deleteStamp(e) }} className="small cancelButton">Ta bort</button>
      </div>
    </div>
  );
}