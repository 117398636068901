import moment from "moment";
import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../api/Api";
import { Context } from "../../redux/Context";
import Stamp from "./Stamp";

export default function Split() {

  const context = useContext(Context);
  const [race, setRace] = useState(null);
  const [bib, setBib] = useState(null);
  const [participant, setParticipant] = useState(null);
  const { raceId, splitId } = useParams();
  const [stamps, setStamps] = useState();
  const datastore = Api();

  useEffect(() => {
    setRace(context.race);
  }, [context]);

  useEffect(() => {
    console.log('bib', bib);
    searchBib();
  }, [bib]);

  useEffect(() => {
    if (raceId && splitId)
      getStamps();
  }, [raceId, splitId]);

  const getStamps = () => {
    datastore.getStamps(raceId, splitId).then(data => {
      setStamps(data);
    }).catch(error => {
      console.log(error);
    });
  }

  const searchBib = () => {
    datastore.searchBib(raceId, bib || 'null').then(data => {
      setParticipant((prev) => ({
        ...prev,
        bib: bib,
        participantId: data.ID,
        firstName: data.firstName,
        lastName: data.lastName,
        club: data.club,
        classId: data.classId,
        className: data.className,
        courseName: data.courseName
      }));

    }).catch(error => {
      console.log(error);
    });
  }

  const addStamp = (e) => {
    e.preventDefault();
    const stamp = {
      bib: bib,
      timestamp: moment().unix()
    }
    datastore.addStamp(raceId, splitId, stamp).then(data => {
      setStamps((prev) => [{ ...data, new: true }, ...prev])
      setBib('');
    }).catch(error => {
      console.log(error);
    });
  }

  const deleteStamp = (stamp) => {
    setStamps((prev) => (prev.filter(item => item.ID != stamp.ID)));
  }

  return (<>
    <Context.Provider value={{ ...context, stamps: stamps, deleteStamp }}>
      <h2>Tidtagning</h2>
      <form >
        <div className="flex">
          <input type="number" placeholder="BIB" max="9999" min="0" value={bib || ''} onChange={(e) => { setBib(e.target.value) }}
            onKeyDownCapture={(e) => {
              if (e.keyCode == 13 || e.key == 'Enter')
                addStamp(e)

            }}
          />
          {participant && participant.participantId &&
            <input type="text" size="60" disabled value={
              `${participant.firstName} ${participant.lastName}, ${participant.club} - ${participant.className}`
            } />
          }
        </div>
      </form>
      <div className="scroller">
        {stamps?.map((stamp, index) => <Stamp stamp={stamp} key={index} />)}
      </div>
    </Context.Provider>
  </>);

}

