import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Api from "../../api/Api";
import { Context } from "../../redux/Context";
import Race from "./Race";
import MaterialCommunityIcons from "../MaterialCommunityIcons";

export default function Participants(props) {

  const { raceId } = useParams();
  const dataStore = Api();
  const [participants, setParticipants] = useState([]);
  const [filter, setFilter] = useState({});
  const context = useContext(Context)
  const [newParticipant, setNewParticipant] = useState({});
  const [editParticipant, setEditParticipant] = useState(false);

  useEffect(() => {
    getParticipants();
  }, []);

  const getParticipants = () => {
    dataStore.getParticipants(raceId).then(data => {
      setParticipants(data);
      console.log('participants', data);
    }).catch(error => {
      console.log(error);
    })
  }

  const deleteParticipant = (participant) => {
    if (!window.confirm('Vill du verkligen ta bort deltagaren?'))
      return;
    dataStore.deleteParticipantFromRace(raceId, participant.ID).then(data => {
      getParticipants();
    }).catch(error => {
      console.log(error);
    })
  }

  const filterFunction = (participant) => {
    if (!filter)
      return participant

    const searchValue = filter.search?.toLowerCase();
    if (!filter.classId || filter.classId == '' || filter.classId == participant.classId) {

      if (searchValue == '' || searchValue == null)
        return participant;
      if (participant.firstName?.toLowerCase().includes(searchValue))
        return participant;
      if (participant.lastName?.toLowerCase().includes(searchValue))
        return participant;
      if (participant.bib?.toString().toLowerCase().includes(searchValue))
        return participant;
      if (participant.club?.toString().toLowerCase().includes(searchValue))
        return participant;
    }
    return false;

  }

  return (<>

    <h2>Deltagare</h2>

    <div className="spacebetween">
      <div className="flex">
        <select onChange={(e) => { setFilter((prev) => ({ ...prev, classId: e.target.value })) }} value={filter.classId} defaultValue={filter.classId}>
          <option value="">Alla klasser</option>
          {context?.race?.classes?.map((raceClass) => <option key={raceClass.ID} value={raceClass.ID}>{raceClass.name}</option>)}

        </select>
        <input type="search" placeholder="Sök deltagare namn, nr, klubb" size="30" onChange={(e) => { setFilter((prev) => ({ ...prev, search: e.target.value })) }} />
      </div>
      <input type="button" value="Lägg till" onClick={() => { setEditParticipant({}) }} />
    </div>
    {editParticipant && <ParticipantForm participant={editParticipant} onSave={() => { getParticipants() }} onClose={() => { setEditParticipant(false) }} />}
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Namn</th>

          <th>Klass</th>
          <th className="hideOnMobile">Bana</th>
          <th>Starttid</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {participants.filter(filterFunction).map((participant) => <tr key={participant.ID}>
          <td><Link to={`/races/${raceId}/participants/${participant.ID}`}>{participant.bib}</Link></td>
          <td>
            <Link to={`/races/${raceId}/participants/${participant.ID}`}>{participant.firstName} {participant.lastName}</Link><br/>
            {participant.club || 'Ingen klubb'}
          </td>
          <td >{participant.className}</td>
          <td className="hideOnMobile">{participant.courseName}</td>
          <td>{participant.startTime ? moment.unix(participant.startTime).format('LT') : '--:--'}</td>
          <td className="flex">
            <a onClick={() => { setEditParticipant(participant) }}>
              <MaterialCommunityIcons name="mdiAccountEdit" />
            </a>
          
            <a onClick={() => { deleteParticipant(participant) }}>
              <MaterialCommunityIcons name="mdiDelete" />
            </a>

          </td>
        </tr>)}
      </tbody>
    </table>
  </>);
}

function ParticipantForm(props) {

  const [participant, setParticipant] = useState(props.participant || {});
  const context = useContext(Context)
  const dataStore = Api();
  const { raceId } = useParams();
  const [isSavingParticipant, setIsSavingParticipant] = useState(false);
  const [status, setStatus] = useState({});

  useEffect(() => {
    setParticipant(props.participant || {});
  }, [props.participant]);

  const saveParticipant = () => {
    setIsSavingParticipant(true);
    dataStore.saveParticipant(raceId, participant).then(data => {
      setStatus({ success: 'Deltagaren sparad' });
      if (props.onSave)
        props.onSave(data);
      if (!participant.ID)
        setParticipant({});
      context.refreshRace();
    }).catch(e => {
      setStatus({ error: e.error });
      console.log(e);
    }).finally(() => {
      setIsSavingParticipant(false);
      setTimeout(() => {
        setStatus({});
      }, 5000);
    })
  }



  return (
    <form className="object">
      {status.error && <div className="formError">{status.error}</div>}
      {status.success && <div className="formSuccess">{status.success}</div>}
      <label>Förnamn & Efternamn</label>
      <div className="flex">
        <input type="text" value={participant.firstName || ''} onChange={(e) => { setParticipant((prev) => ({ ...prev, firstName: e.target.value })) }} />
        <input type="text" value={participant.lastName || ''} onChange={(e) => { setParticipant((prev) => ({ ...prev, lastName: e.target.value })) }} />
      </div>
      <label>Klubb</label>
      <input type="text" value={participant.club || ''} onChange={(e) => { setParticipant((prev) => ({ ...prev, club: e.target.value })) }} />
      <label>Klass</label>
      <select value={participant.classId || ''} name="classId" onChange={(e) => { setParticipant((prev) => ({ ...prev, classId: e.target.value })) }}>
        <option value="">Alla klasser</option>
        {context?.race?.classes?.map((raceClass) => <option key={raceClass.ID} value={raceClass.ID}>{raceClass.name}</option>)}
      </select>
      <label>Bib</label>
      <input type="number" value={participant.bib || ''} onChange={(e) => { console.log(e.target.value); setParticipant((prev) => ({ ...prev, bib: e.target.value })) }} />
      <div className="spacebetween">
        <input type="button" value="Spara" disabled={isSavingParticipant} onClick={() => { saveParticipant() }} />
        <input type="button" value="Avbryt" onClick={() => { props.onClose && props.onClose() }} className="cancelButton" />
      </div>

    </form>
  )
}