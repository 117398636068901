import moment from "moment";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../api/Api";
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';
import MaterialCommunityIcons from "../MaterialCommunityIcons";

function Class(props, ref) {

  const [raceClass, setRaceClass] = useState(false);
  const { classId } = useParams();
  const dataStore = Api();
  const [timer, setTimer] = useState();
  const [delta, setDelta] = useState();
  const [curTime, setCurTime] = useState(moment());
  let intervalId;
  const [isRunning, setIsRunning] = useState(false);
  const [editing, setEditing] = useState(false);

  useImperativeHandle(ref, () => ({
    setAutostart: (autostart) => { return setAutostart(autostart) },
    startClass: () => { return startClass() },
    stopClass: () => { return stopClass() },
    refresh: refresh,
    isRunning: isRunning
  }))

  useEffect(() => {
    intervalId = setInterval(() => {
      setCurTime(moment());
    }, 100);
    return () => {

      clearInterval(intervalId);
    }
  }, [])

  useEffect(() => {
    setTimer(toTime());
  }, [curTime]);

  useEffect(() => {
    setRaceClass(props.class);

  }, [props.class]);


  useEffect(() => {
    if (classId) {
      refresh();
    }
  }, [classId]);

  useEffect(() => {
    if (delta > 0 && raceClass.autostart && raceClass.status == 'not started') {
      startClass(raceClass.startTime);
    }
  }, [delta]);



  const refresh = () => {
    getClass();
  }

  const getClass = () => {
    dataStore.getClass(classId).then(data => {
      setRaceClass(data);
      props.class = data
    }).catch(error => {
      console.log(error);
    })
  }

  const toTime = (startTime) => {
    if (!raceClass)
      return;
    if (!raceClass.startTime || raceClass.status == 'finished') {
      clearInterval(intervalId);
      return '00:00:00.0';
    }
    var delta = curTime.valueOf() - (raceClass.startTime * 1000);
    setDelta(delta);
    var date = moment(Math.abs(delta))
    if (delta > 0 && raceClass.status == 'not started') {
      return moment(moment.duration(Math.abs(delta)).valueOf()).utc().format('HH[h] mm[m] ss.S[s]');
    }
    //2104
    if (delta < 0) {
      const days = moment.unix(raceClass.startTime).diff(curTime, 'days');
      const string = moment(moment.duration(Math.abs(delta)).valueOf()).utc().format('-HH[h] mm[m] ss.S[s]');
      return ((days > 0 ? days + ' dagar ' : '') + string);
    } else {
      const days = curTime.diff(moment.unix(raceClass.startTime), 'days');
      const string = moment(moment.duration(Math.abs(delta)).valueOf()).utc().format('HH:mm:ss.S');
      return ((days > 0 ? days + ' dagar ' : '') + string);
    }
  }

  const setAutostart = (autostartTime) => {

    if (raceClass.status == 'started' || raceClass.status == 'finished')
      return;
    saveClass({ ...raceClass, autostart: true, startTime: moment().add(autostartTime, 'seconds').unix() });
  }

  const startClass = (startTime) => {
    if (raceClass.status == 'started' || raceClass.status == 'finished')
      return;
    saveClass({ ...raceClass, status: 'started', startTime: startTime || curTime.unix() });
  }

  const stopClass = () => {
    clearInterval(intervalId);
    saveClass({ ...raceClass, status: 'finished', endTime: curTime.unix() });
  }

  const saveClass = (raceClass) => {
    dataStore.saveClass(raceClass).then(data => {
      setRaceClass(data);
      console.log('saveClass', data);
      if (props.onChange)
        props.onChange(data);
    }).catch(error => {
      console.log(error);
    })
  }

  return (<>
    <div className="raceClass">

      <div className="classInfo flex">
        <div>
          <div className="className">{raceClass.name}</div>
          <div className="classCourse iconBox">
            <MaterialCommunityIcons name={"mdiGesture"} />
            <span>{raceClass.courseName}</span>
          </div>
          <div className="participantCount iconBox">
            <MaterialCommunityIcons name={"mdiAccountGroup"} />
            <span>{raceClass.participantCount}</span>
          </div>
        </div>
        <div>
          <div className="startTime iconBox">
            <MaterialCommunityIcons name={"mdiClockAlertOutline"} />
            <span>{moment.unix(raceClass.startTime).format('LTS')}</span>

          </div>
          <div className="autoStart iconBox">
            <MaterialCommunityIcons name={"mdiRefreshAuto"} />
            <span>{raceClass.autostart ? 'Autostart' : 'Manuell'}</span>

          </div>
          <div className="classStatus iconBox">
            <MaterialCommunityIcons name={"mdiTimerStopOutline"} />
            {raceClass.status != 'finished' ? <span>{raceClass.status}</span> : <span>{raceClass.endTime ? moment.unix(raceClass.endTime).format('LTS') : '--:--:--'}</span>}
          </div>
        </div>
      </div>

      <div className={["classTimer", "iconBox", raceClass.status == 'started' ? 'started' : null].join(' ')}>
        <MaterialCommunityIcons name={raceClass.status == 'started' ? 'mdiTimerOutline' : 'mdiTimerOffOutline'} />
        <span className="timer">{timer}</span>
      </div>

      <div className="classControl flex">
        {raceClass.status == 'started' && <button className="cancelButton small" onClick={stopClass}>Avsluta</button>}
        {raceClass.status == 'not started' && <button className="small" onClick={() => startClass()} >Starta klass</button>}
        {raceClass.status == 'not started' &&
          <button className="small" onClick={() => {
            setAutostart(5);
          }}>Autostart om 5 sekunder</button>
        }
      </div>
      <div className="editIcon">
        <a onClick={() => { setEditing(!editing) }}><MaterialCommunityIcons name="mdiCog" size={'1.25rem'} /></a>
      </div>
      

    </div>
    {raceClass && editing && <ClassForm raceClass={raceClass} onSave={saveClass} />}
  </>);
}

function ClassForm(props) {

  const [raceClass, setRaceClass] = useState(props.raceClass);
  const [tempDate, setTempDate] = useState();

  useEffect(() => {
    setRaceClass(props.raceClass);
    setTempDate(moment.unix(props.raceClass.startTime).format('YYYY-MM-DDTHH:mm'));
  }, [props.raceClass]);

  useEffect(() => {
    if (tempDate) {
      setRaceClass({ ...raceClass, startTime: moment(tempDate).unix() });
    }
    console.log('tempDate', tempDate);
  }, [tempDate]);

  const saveClass = () => {
    if (props.onSave)
      props.onSave(raceClass);
  }

  return (
    <form className="object">
      <label>Namn</label>
      <input type="text" value={raceClass.name || ''} onChange={(e) => { setRaceClass({ ...raceClass, name: e.target.value }) }} />
      <label>Startdatum & tid {raceClass.name}</label>
      <input type="datetime-local"
        max="2050-12-31T23:59"
        value={tempDate || ''}
        onChange={(e) => { setTempDate(e.target.value) }}
      />
      <label>Autostart</label>
      <Switch value={raceClass.autostart} onChange={(value) => { setRaceClass({ ...raceClass, autostart: value }) }} />
      <input type="button" value="Spara" onClick={() => { saveClass() }} />

    </form>
  )
}

function Switch(props) {

  useEffect(() => {

  }, [props.value]);


  return (
    <div className="switch">
      <a onClick={() => { props.onChange(!props.value) }}>
        <MaterialCommunityIcons name={props.value ? 'mdiToggleSwitch' : 'mdiToggleSwitchOffOutline'} size="3rem" />
      </a>
    </div>
  )
}

export default forwardRef(Class);