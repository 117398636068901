import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Api from "../../api/Api";
import { Tabs,Tab } from "../Tabs";

export default function Participant() {

  const [participant, setParticipant] = useState({});
  const [history, setHistory] = useState([]);
  const [splits, setSplits] = useState([]);

  const dataStore = Api();
  const { raceId, participantId } = useParams();

  useEffect(() => {
    getParticipant();
    getHistory();
    getParticipantSplits();
  }, [participantId, raceId]);

  const getParticipant = () => {
    dataStore.getParticipant(participantId, raceId).then(data => {
      setParticipant(data);
    }).catch(error => {
      console.log(error);
    })
  }

  const getHistory = () => {
    dataStore.getHistory(participantId).then(data => {
      setHistory(data);
      console.log(data);
    }).catch(error => {
      console.log(error);
    })
  }

  const getParticipantSplits = () => {
    dataStore.getParticipantSplits(raceId, participantId).then(data => {
      console.log(data);
      setSplits(data);
    }).catch(error => {
      console.log(error);
    })
  }

  const formatDuration = (duration) => {
    const diff =  moment.duration(duration, 'seconds');
    const string = moment.utc(diff.asMilliseconds()).format('HH:mm:ss');
    return string;
  }

  return (<>
  <h2>{participant.bib} {participant.firstName} {participant.lastName}</h2>
    <Tabs>
      <Tab title="Deltagarinformation">
        
        <h4>Detaljer</h4>
        <div className="details">
          <div className="item">Klubb/Ort</div>
          <div className="value">{participant.club}</div>
          <div className="item">Bana </div>
          <div className="value">{participant.courseName}</div>
          <div className="item">Klass </div>
          <div className="value">{participant.className}</div>
          <div className="item">Starttid</div>
          <div className="value">{participant.startTime ? moment.unix(participant.startTime).format('LTS') : '--:--'}</div>
        </div>

        <h4>Mellantider</h4>
    <table>
      <thead>
        <tr>
          <th>Sträcka</th>
          <th className="number">Placering</th>
          <th className="time">Tid</th>
          <th className="time">Total tid</th>
          <th className="time">Sträcktid</th>
        </tr>
      </thead>
      <tbody>
        {splits.map(split => <tr key={split.splitId + '_' + split.splitOrder}>
          <td>{split.splitName}</td>
          <td className="number">{split.classPlace}</td>
          <td className="time">{split.timestamp ? moment.unix(split.timestamp).format('LTS') : '--:--'}</td>
          <td className="time">{split.splitTime && split.startTime ? formatDuration(split.timestamp - split.startTime): '--:--'}</td>
          <td className="time">
            {split.splitTime ? formatDuration(split.splitTime) : '--:--'}
            <span className="splitPlace">{split.splitPlace}</span>
          </td>
        </tr>)}
      </tbody>
    </table>

      </Tab>
      <Tab title="Historik">
        <h4>Historik</h4>
        <table>
          <thead>
            <tr>
              <th>Datum</th>
              <th>Lopp</th>
              <th>Course</th>
              <th>Placering</th>
              <th>Tid</th>
              <th>Diff</th>
            </tr>
          </thead>
          <tbody>
            {history.map(result => <tr key={result.ID}>
              <td>{moment.unix(result.raceDate).format('L')}</td>
              <td><Link to={`/races/${result.raceId}/result`}>{result.raceName}</Link></td>
              <td>{result.courseName}</td>
              <td>{result.classPlace}</td>
              <td>{result.endTime ? moment.unix(result.endTime).format('mm:ss') : '--:--'}</td>
              <td></td>
            </tr>)}
          </tbody>
        </table>
      </Tab>
    </Tabs>
    

  </>);
}