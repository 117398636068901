import Icon from '@mdi/react';
import * as iconPath from '@mdi/js';
import { useEffect, useState } from 'react';

export default function MaterialCommunityIcons(props) {
  const [icon, setIcon] = useState(iconPath[props.name]);
  let { name, size, color } = props;
  size = size ? size : '1em';
  useEffect(() => {
    setIcon(iconPath[props.name]);
  }, [props.name]);

  return (icon ?

    <Icon path={icon} className={[props.className, "mdi"].join(' ')} size={size} color={color} />

    : null)
}