import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../redux/Context";
import { Link, useParams } from "react-router-dom";
import Api from "../../api/Api";

export default function Courses(props) {

  const context = useContext(Context);
  const { raceId } = useParams();
  const [race, setRace] = useState(null);
  const [courses, setCourses] = useState([]);
  const newCourseInput = useRef();
  const dataStore = Api();

  useEffect(() => {
    setRace(context.race);
  }, [context]);

  useEffect(() => {
    getCourses();
  }, [raceId]);

  const createCourse = () => {
    if(!newCourseInput.current.value)
      return;
    dataStore.createCourse(raceId,{name:newCourseInput.current.value}).then(data => {
      getCourses();
    }).catch(error => {
      console.log(error);
    })
  }

  const getCourses = () => {
    
    dataStore.getCourses(raceId).then(data => {
      console.log('courses', data);
      setCourses(data);
    }).catch(error => {
      console.log(error);
    })
  }



  return (<>
    <h2>Banor</h2>
    <div className="flex">
      <input type="text" placeholder="Namn på bana" ref={newCourseInput}/>
      <input type="button" value="Lägg till" onClick={createCourse} />
    </div>
    <table>
      <thead>
        <tr>
          <th>Namn</th>
        </tr>
      </thead>
      <tbody>
        {courses.map((course) => {
          return (<tr key={course.ID}>
            <td>
              <Link to={`/races/${raceId}/courses/${course.ID}`}>{course.name}</Link>
            </td>
            <td>{course.description}</td>
          </tr>)
        })}
      </tbody>
    </table>
  </>)
  /*
  return (
    <>
      <div className="raceClass">
        <div className="classInfo flex">
          <div>
            <div className="className">{raceClass.name}</div>
            <div className="classCourse iconBox">
              <MaterialCommunityIcons name={"mdiGesture"} />
              <span>{raceClass.courseName}</span>
            </div>
            <div className="participantCount iconBox">
              <MaterialCommunityIcons name={"mdiAccountGroup"} />
              <span>{raceClass.participantCount}</span>
            </div>
          </div>
          <div>
            <div className="startTime iconBox">
              <MaterialCommunityIcons name={"mdiClockAlertOutline"} />
              <span>{moment.unix(raceClass.startTime).format('LTS')}</span>
            </div>
            <div className="autoStart iconBox">
              <MaterialCommunityIcons name={"mdiRefreshAuto"} />
              <span>{raceClass.autostart ? 'Autostart' : 'Manuell'}</span>
            </div>
            <div className="classStatus iconBox">
              <MaterialCommunityIcons name={"mdiTimerStopOutline"} />
              {raceClass.status != 'finished' ? <span>{raceClass.status}</span> : <span>{raceClass.endTime ? moment.unix(raceClass.endTime).format('LTS') : '--:--:--'}</span>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
  */
}