import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Api from "../../api/Api";
import { Context } from "../../redux/Context";

export default function Splits() {

  const context = useContext(Context);
  const { raceId } = useParams();
  const [race, setRace] = useState(null);
  const [bib, setBib] = useState(null);
  const [splits, setSplits] = useState([]);
  const dataStore = Api();

  useEffect(() => {
    setRace(context.race);
    console.log(context);
  }, [context]);

  useEffect(() => {
    getRaceSplits();
  }, [raceId]);

  const getRaceSplits = () => {
    dataStore.getRaceSplits(raceId).then(data => {
      setSplits(data);
      
    }).catch(error => {
      console.log(error);
    })
  }


  return (<>
    <h2>Mellantider/Stationer</h2>
    <table>
      <thead>
        <tr>
          <th>Namn</th>
        </tr>
      </thead>
      <tbody>
        {splits.map((split) => {
          return (<tr key={split.ID}>
            <td>
              <Link to={`/races/${raceId}/splits/${split.ID}`}>{split.name}</Link>
            </td>
          </tr>)
        })}
      </tbody>
    </table>
  </>)

}