import { useContext } from "react";
import { useApi } from "../hooks/ApiProvider";
import { Context } from "../redux/Context";

export default function Login() {

  const Api = useApi()
  const appContext = useContext(Context);

  const loginUser = (e) => {
    e.preventDefault();
    
    const username = e.target.username.value;
    const password = e.target.password.value;
    Api.login({username, password}).then((token) => {
      if (token) {
        console.log('login', token);
        appContext.dispatch({ type: 'login' });
      }
    }).catch((e) => {
      console.log(e)
    })
  }


  return (

    <div className="flex spacearound">
      <img src="/reacttimer.svg" className="logo" />
      <form className="loginForm"  onSubmit={(event) => { loginUser(event) }}>
        <label>Användarnamn</label>
        <input type="text" name="username" placeholder="Ange e-postadress" size="30" />
        <label>Lösenord</label>
        <input type="password" name="password" placeholder="Ange lösenord" size="30" />
        <input type="submit" value="Logga in" className="button" />
      </form>
    </div>)
}