import { useContext, useEffect, useState } from "react";
import { Context } from "../../redux/Context";
import Api from "../../api/Api";
import { Link } from "react-router-dom";
import moment from "moment";
import 'moment/locale/sv'
import Race from "./Race";

export default function Races() {

  const appContext = useContext(Context);
  const [races, setRaces] = useState([]);
  const dataStore = Api();

  useEffect(() => {
    dataStore.getRaces().then(result => {
      setRaces(result);
    }).catch(error => {
      console.log(error);
    })
  }, []);

  return (<>
    <h1>Lopp</h1>
    <div className="table">
      {races.map((race) => <Race race={race} key={race.ID} />)}
    </div>
  </>);
}