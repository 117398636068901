import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../redux/Context";
import { useParams } from "react-router-dom";
import Api from "../../api/Api";
import MaterialCommunityIcons from "../MaterialCommunityIcons";

export default function Course(props) {

  const context = useContext(Context);
  const { raceId, courseId } = useParams();
  const [race, setRace] = useState(null);
  const [course, setCourse] = useState([]);
  const [courseSplits, setCourseSplits] = useState([]);
  const [splits, setSplits] = useState([]);
  const splitSelect = useRef();
  const dataStore = Api();

  useEffect(() => {
    setRace(context.race);
  }, [context]);

  useEffect(() => {
    race && setCourse(race.courses?.find(course => course.ID === parseInt(courseId)));
  }, [race]);

  useEffect(() => {
    getCourseSplits();
    getRaceSplits();
  }, [raceId, courseId]);

  const getCourseSplits = () => {
    dataStore.getCourseSplits(raceId, courseId).then(data => {
      setCourseSplits(data);
    }).catch(error => {
      console.log(error);
    })
  }

  const getRaceSplits = () => {
    dataStore.getRaceSplits(raceId).then(data => {
      setSplits(data);
    }).catch(error => {
      console.log(error);
    })
  }

  const addSplitToCourse = () => {
    dataStore.addSplitToCourse(raceId, courseId, splitSelect.current.value).then(data => {
      getCourseSplits();
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      splitSelect.current.selectedIndex = 0;
    })
  }

  const moveSplitUp = (splitId) => {
    const index = courseSplits.findIndex(split => split.courseSplitId === splitId);
    if (index === 0 || index === -1)
      return;
    [courseSplits[index], courseSplits[index - 1]] = [courseSplits[index - 1], courseSplits[index]];
    setCourseSplits(courseSplits.map((split, i) => {
      split.sortOrder = i;
      updateCourseSplit(split);
      return split;
    }));

  }

  const moveSplitDown = (splitId) => {
    const index = courseSplits.findIndex(split => split.courseSplitId === splitId);
    if (index === courseSplits.length - 1 || index === -1)
      return;
    courseSplits[index].sortOrder = index + 1;
    [courseSplits[index], courseSplits[index + 1]] = [courseSplits[index + 1], courseSplits[index]];
    setCourseSplits(courseSplits.map((split, i) => {
      split.sortOrder = i;
      updateCourseSplit(split);
      return split;
    }));

  }

  const deleteSplit = (courseSplitId) => {
    dataStore.deleteSplitFromCourse(raceId, courseId, courseSplitId).then(data => {
      setCourseSplits(courseSplits.filter(split => split.courseSplitId !== courseSplitId));
    }).catch(error => {

    }).finally(() => {

    });
  }

  const updateCourse = (course) => {
    dataStore.saveCourse(raceId, courseId, course).then(data => {
      setCourse(course);
      context.refreshRace()
    }).catch(error => {
      console.log(error);
    })
  }


  const updateCourseSplit = (courseSplit) => {

    dataStore.saveCourseSplit(raceId, courseId, courseSplit).then(data => {
      setCourseSplits((prev) => {
        prev.splice(prev.findIndex(item => item.courseSplitId == courseSplit.courseSplitId), 1, courseSplit);
        return [...prev]
      });
    }).catch(error => {
      console.log(error);
    })
  }

  return (
    course ?
      <>

        <CourseDescription course={course} onChange={(value) => { updateCourse({ ...course, ...value }) }} />

        <h3>Mellantider</h3>

        <div>
          <label>Lägg till mellantider</label>
          <div className="flex">
            <select ref={splitSelect}>
              <option value="">Välj</option>
              {splits.map((split) => {
                return (<option key={split.ID} value={split.ID}>{split.name}</option>)
              })}
            </select>
            <button type="button" onClick={addSplitToCourse}>Lägg till</button>
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th>Namn</th>
              <th>Distans (km)</th>
              <th>Typ</th>
            </tr>
          </thead>
          <tbody>
            {courseSplits.map((split) => {
              return (<tr key={split.courseSplitId}>
                <td>{split.name}</td>
                <td>
                  <DistanceInput split={split} onChange={(split) => updateCourseSplit(split)} />
                </td>
                <td>
                  <select value={split.type} onChange={(e) => { updateCourseSplit({ ...split, type: e.target.value }) }} values={split.type}>
                    <option value="intermediate">Mellantid</option>
                    <option value="finish">Mål</option>
                  </select>
                </td>
                <td>
                  <a onClick={() => moveSplitUp(split.courseSplitId)}><MaterialCommunityIcons name="mdiArrowUpThick" size={'1.25rem'} /></a>

                  <a onClick={() => moveSplitDown(split.courseSplitId)}><MaterialCommunityIcons name="mdiArrowDownThick" size={'1.25rem'} /></a>
                </td>
                <td>
                  <a onClick={() => { deleteSplit(split.courseSplitId) }}>
                    <MaterialCommunityIcons name="mdiDelete" size={'1.25rem'} />
                  </a>
                </td>
              </tr>)
            })}
          </tbody>
        </table>
      </>
      : <><p>Hämtar data</p></>
  )

}

function CourseName(props) {
  const [value, setValue] = useState(props.value);
  const [editing, setEditing] = useState(false);
  const input = useRef();

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const saveValue = () => {
    setEditing(false);
    if (value != props.value)
      props.onChange(value);
  }

  return (
    <>
      {!editing ?
        <h2><a onClick={() => { setEditing(!editing) }}>{value || 'Inget namn'}</a></h2>
        :
        <div>
          <input type="text" value={value} onChange={(e) => { setValue(e.target.value) }} ref={input} placeHolder='Namn på banan' />
          <input type="button" value="OK" onClick={saveValue} />
        </div>
      }
    </>
  )

}

function CourseDescription(props) {
  const [course, setCourse] = useState(props.course);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setCourse(props.course);
  }, [props.course]);

  const saveValue = () => {
    setEditing(false);
    if (course != props.course)
      props.onChange(course);
  }

  return (
    <>
      {!editing ?
        <>
          <h2><a onClick={() => { setEditing(!editing) }}>{course.name || 'Inget namn'}</a></h2>
          <p>
            <a onClick={() => { setEditing(!editing) }}>{course.description || 'Ingen beskrivning'}</a>
          </p>
        </>
        :
        <>
          <label>Namn</label>
          <input type="text" value={course.name} onChange={(e) => { setCourse((prev) => ({ ...prev, name: e.target.value })) }} placeHolder='Namn på banan' />
          <label>Beskrivning</label>
          <input type="text" value={course.description} onChange={(e) => { setCourse((prev) => ({ ...prev, description: e.target.value })) }} placeHolder='Kort beskrivning av banan' />
          <input type="button" value="OK" onClick={saveValue} />
        </>
      }
    </>
  )
}

function DistanceInput(props) {
  const { split } = props;
  const [value, setValue] = useState(split.distance || '');
  const [editing, setEditing] = useState(false);
  const input = useRef();

  useEffect(() => {
    setValue(split.distance || '');
  }, [split]);

  const saveValue = () => {
    setEditing(false);
    if (value != split.distance) {
      console.log('save');
      props.onChange({ ...split, distance: value });
    }
  }

  return (
    <>
      {!editing ?
        <a onClick={() => { setEditing(!editing) }}>{value || '---'}</a>
        :
        <>
          <input type="number" value={value} onChange={(e) => { setValue(e.target.value) }} min={.1} max={1000} ref={input} />
          <input type="button" value="OK" onClick={saveValue} />
        </>
      }



    </>
  )
}