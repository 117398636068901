import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../api/Api";
import { Context } from "../../redux/Context";
import Class from "./Class";

export default function Classes(props) {

  const context = useContext(Context);
  const [race, setRace] = useState(null);
  const classRefs = useRef([]);
  const [autostartInterval, setAutostartInterval] = useState(5);
  const [viewClassForm, setViewClassForm] = useState(false);

  useEffect(() => {
    setRace(context.race);
  }, [context]);

  useEffect(() => {
    console.log(classRefs);
  }, [classRefs]);

  const startAll = () => {
    classRefs.current.map(ref => {
      ref.startClass();
    })
  }

  const setAutostart = () => {
    if (!autostartInterval)
      return;
    classRefs.current.map(ref => {
      ref.setAutostart(autostartInterval);
    })
  }

  return (<>
    <h2>Klasser</h2>
    <div className="raceControl">

      <div className="autoStart">
        <label>Autostarta alla om</label>
        <div className="flex">
          <input type="number" value={autostartInterval} placeholder="5 sek" size="2" min="5" max="60" onChange={(e) => { setAutostartInterval(e.target.value) }} />
          <button onClick={() => setAutostart()}>Autostart</button>
        </div>
      </div>
      <div className="manualStart">
        <label>Direktstart</label>
        <button onClick={() => startAll()}>Starta alla nu</button>
      </div>
    </div>

    <div>
      <input type="button" value="Lägg till klass" onClick={() => { setViewClassForm(!viewClassForm) }} />
    </div>

    {viewClassForm && <ClassForm />}

    {race?.classes?.map((raceClass, index) => <Class class={raceClass} race={race} key={raceClass.ID} ref={(ref) => classRefs.current[index] = ref} onChange={(raceClass) => race.classes[index] = raceClass} />)}


  </>);
}

function ClassForm(props) {

  const [raceClass, setRaceClass] = useState({});
  const context = useContext(Context);
  const [race, setRace] = useState(context.race);
  const dataStore = Api();

  useEffect(() => {

  }, []);

  const createClass = () => {
    console.log('createClass', raceClass);
    if (!raceClass.courseId || !raceClass.name)
      return;
    dataStore.createClass(race.ID, raceClass).then(data => {
      context.refreshRace();
    }).catch(error => {
      console.log(error);
    }).finally(() => {
    })

  }

  return (
    <form className="object flex">
      <div>
        <label>Namn</label>
        <input type="text" placeHolder="Namn på klass" size="30" value={raceClass.name} onChange={(e) => { setRaceClass((prev) => ({ ...prev, name: e.target.value })) }} />
      </div>
      <div>
        <label>Bana</label>
        <div class="flex">
          <select value={raceClass.courseId || ''} name="courseId" onChange={(e) => { setRaceClass((prev) => ({ ...prev, courseId: e.target.value })) }}>
            <option value="">Välj bana</option>
            {race.courses.map(course => {
              return (
                <option value={course.ID}>{course.name}</option>
              )
            }
            )}
          </select>
          <input type="button" value="Lägg till klass" onClick={createClass} />
        </div>
      </div>

    </form>
  )

}